<template>
  <div
    class="
      widgetContainer
      header-white
      widgetContainer--scrollable
      checkDeposit
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="backAction" />
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body checkDeposit__body">
      <el-form
        class="wise-form"
        @keyup.enter.native="submit()"
        ref="formRef"
        :rules="rules"
        :model="formData">
        <el-form-item
          class="is-no-asterisk"
          prop="name"
          :label="$t('RCD_captureScreen_payorName')">
          <el-input readonly v-model="formData.name" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="amount"
          :label="$t('RCD_captureScreen_enterAmount')">
          <Amount
            :precision="2"
            v-model.number="formData.amount"
            :masked="false" />
        </el-form-item>

        <div>
          <p class="header-2">
            {{ $t('RCD_captureScreen_addCheckImages') }}
          </p>

          <FileSelect
            :value="formData.frontImg"
            :title="$t('RCD_frontTitle')"
            @fileChange="fileOnChange($event, 'front')"
            class="border-1">
            <ul>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>

          <FileSelect
            :value="formData.backImg"
            :title="$t('RCD_backTitle')"
            @fileChange="fileOnChange($event, 'back')">
            <ul>
              <li>{{ RCD_captureInstruction4 }}</li>
              <li>{{ $t('RCD_captureInstruction1') }}</li>
              <li>{{ $t('RCD_captureInstruction2') }}</li>
              <li>{{ $t('RCD_captureInstruction3') }}</li>
            </ul>
          </FileSelect>
        </div>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        class="submit el-button__brand width-100 brand"
        @click="submit">
        {{ $t('next') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Amount from '@/components/Amount';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileSelect from '../components/FileSelect';
import { getBrandKey } from '@/utils/env';

export default {
  components: {
    Amount,
    FileSelect
  },
  data() {
    return {
      frontChecked: false,
      backChecked: false,
      formData: {
        amount: '',
        name: '',
        frontImg: null,
        backImg: null
      },
      rules: {
        amount: [
          {
            type: 'number',
            required: true,
            trigger: 'blur'
          }
        ],
        name: [{ required: true }],
        frontImg: [{ required: true }],
        backImg: [{ required: true }]
      }
    };
  },
  created() {
    this.formData.name = this.getSelectedContact.name;
    if (this.getCheckDepositFormData) {
      this.formData.amount = this.getCheckDepositFormData.checkData.amount;
      // this.formData.name = this.getCheckDepositFormData.checkData.description;
      this.formData.frontImg = this.getCheckDepositFormData.files.front;
      this.formData.backImg = this.getCheckDepositFormData.files.back;
    }
  },
  computed: {
    ...mapGetters('checkDeposit', [
      'getSelectedContact',
      'getCheckDepositFormData'
    ]),
    ...mapGetters('account', ['getSelectedAccount']),
    isFormValid() {
      return (
        this.formData.amount &&
        this.formData.name &&
        this.formData.backImg &&
        this.formData.frontImg
      );
    },
    RCD_captureInstruction4(){
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key+'_RCD_captureInstruction4') : 'RCD_captureInstruction4')
    }
  },
  methods: {
    ...mapActions('checkDeposit', [
      'createCheckDeposit',
      'updateCheckDepositFiles'
    ]),
    ...mapMutations('checkDeposit', [
      'setSuccessDepositData',
      'setCheckDepositFormData'
    ]),
    backAction() {
      this.setCheckDepositFormData(null);
      // this.goToPreviousScreen();
      this.drawerBack();
    },
    handlePreview(from) {
      if (from === 'front') {
        this.$refs.fileInput1.click();
      } else {
        this.$refs.fileInput2.click();
      }
    },
    fileOnChange(e, from) {
      console.log(e);
      this.formData[from === 'front' ? 'frontImg' : 'backImg'] = e;
    },
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (!valid || !this.isFormValid) return;

        const payload = {
          accountId: this.getSelectedAccount.id,
          contactId: this.getSelectedContact.id,
          amount: this.formData.amount,
          description: this.formData.name
        };

        const files = {
          front: this.formData.frontImg,
          back: this.formData.backImg
        };

        this.setCheckDepositFormData({
          checkData: payload,
          files
        });

        setTimeout(() => {
          this.drawerPush('CheckDeposit-preview');
          // this.$router.push('/check-deposit/preview');
        });
      });
    }
  },
  watch: {
    'formData.amount': function () {
      // if(newVal > 1000) {
      this.$refs.formRef.validate();
      // }
    }
  }
};
</script>

<style lang="scss">
.checkDeposit {
  &__maximum-label {
    padding-bottom: 24px !important;
    text-align: left;
    float: none;
    padding-top: 8px;
  }

  .widgetContainer__body {
    &.checkDeposit__body {
      padding-bottom: 100px;
    }
  }

  .hidden-file {
    display: none;
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #fff;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: var(--branding-primary);
    border-color: var(--branding-primary);
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner::after {
    border-color: #ffffff;
  }
}

.uploadCheckImage {
  border-radius: var(--radius);
  background-color: #fff;
  padding: 16px 24px;
  margin-bottom: 16px;
  border: 1px solid #EDEFF1;
  &__image {
    overflow: hidden;
    height: 200px;

    img {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 13px;
    align-items: center;

    .el-checkbox__inner {
      width: 20px;
      height: 20px;

      &:after {
        top: 5px;
        left: 7px;
      }
    }

    p {
      padding-left: 16px;
      font-family: var(--branding);
    }

    span {
      margin-left: auto;
      text-decoration: underline;
      color: #90909e;
      cursor: pointer;
      font-size: 14px;
    }
  }

  ul {
    padding-bottom: 16px;

    li {
      color: #90909e;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
